<template>
  <div class="score_border">
    <div class="score_border_head">
      <div>
        <h4>
          Super Score
          <small>
            <a
              style="color: white !important"
              href="https://blog.ivy-way.com/what-is-super-score"
              target="_blank"
              ><i
                class="fa fa-question-circle question-icon"
                aria-hidden="true"
              ></i
            ></a>
          </small>
        </h4>
        <h6>
          Your highest section scores.
        </h6>
      </div>
    </div>
    <div class="row" style="padding: 0 20px">
      <div class="col-md-4">
        <h5 class="text-center">Sum of Highest Section Scores</h5>
        <div>
          <div>
            <div class="text-center progress_total">
              {{
                bestScore.max_english.section_english_score +
                  bestScore.max_math.section_math_score
              }}
            </div>
          </div>
          <h5 class="text-center">out of 1600</h5>
        </div>
      </div>
      <div class="col-md-8">
        <div class="row score_section">
          <div class="col-sm-4">
            <h5>
              English:
              <a class="text-success">{{
                bestScore.max_english.section_english_score
              }}</a>
            </h5>
            <a :href="'/sat/result?user_exam_id=' + bestScore.max_english.id">{{
              bestScore.max_english.exam_date != null
                ? bestScore.max_english.exam_date
                : bestScore.max_english.created_at
            }}</a>
          </div>
          <div class="col-sm-8">
            <div style="height: 10px">
              <div
                class="corner"
                :style="{
                  'margin-left': getLeft(
                    bestScore.max_english.section_english_score
                  )
                }"
              ></div>
            </div>
            <div style="height: 47px;line-height: 47px">
              <b style="display:inline-block;width: 10px;">0</b>
              <div class="score_progress"></div>
              <b>800</b>
            </div>
          </div>
        </div>
        <div class="row score_section">
          <div class="col-sm-4">
            <h5>
              Math:
              <a class="text-success">{{
                bestScore.max_math.section_math_score
              }}</a>
            </h5>
            <a :href="'/sat/result?user_exam_id=' + bestScore.max_math.id">{{
              bestScore.max_math.exam_date != null
                ? bestScore.max_math.exam_date
                : bestScore.max_math.created_at
            }}</a>
          </div>
          <div class="col-sm-8">
            <div style="height: 10px">
              <div
                class="corner"
                :style="{
                  'margin-left': getLeft(bestScore.max_math.section_math_score)
                }"
              ></div>
            </div>
            <div style="height: 47px;line-height: 47px">
              <b style="display:inline-block;width: 10px;">0</b>
              <div class="score_progress"></div>
              <b>800</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["bestScore"],
  data() {
    return {
      progress_width: 0
    };
  },
  computed: {},
  watch: {},

  mounted() {
    let progress_width = document.getElementsByClassName("score_progress")[0];
    let width = 0;
    if (progress_width != undefined) {
      width = progress_width.offsetWidth;
    }
    this.progress_width = width;
  },

  methods: {
    getLeft(score) {
      return (score / 800) * this.progress_width + 8 + "px";
    }
  }
};
</script>

<style scoped>
.score:hover .hover-update-icon,
.score_border:hover .hover-update-icon {
  display: inline;
}
.score_border_head {
  padding: 20px;
  background-color: #43a06b;
  color: white;
}
.score_border {
  border: 3px solid #43a06b;
  margin-top: 20px;
}
.score_border_head h3,
.score_border_head h5 {
  color: white;
  margin-bottom: 0;
}
.score_progress {
  display: inline-block;
  width: 70%;
  height: 30px;
  background-color: #ccc;
}
.score_section {
  margin-top: 15px;
  border-bottom: 1px solid #ccc;
}
.corner {
  width: 0px; /*  宽高设置为0，很重要，否则达不到效果 */
  height: 0px;
  border: 8px solid #43a06b;
  margin-left: 7px;
  margin-top: 1px;
  border-bottom-color: transparent; /* 设置透明背景色 */
  border-left-color: transparent;
  border-right-color: transparent;
}
.progress_total {
  margin: 10px auto;
  font-size: 26px;
  font-weight: 700;
  line-height: 62px;
  width: 100px;
  height: 70px;
  border: 4px solid #43a06b;
  color: #ff8920;
}

.maxScore_date {
  font-size: 14px;
}

.this_score .score_section:last-child {
  border-bottom: none;
}
.best_score .score_section:last-child {
  border-bottom: none;
}
.score_progress {
  margin: 0 5px;
}
.score_border_head h4,
.score_border_head h6 {
  color: #fff;
}
h5 {
  font-size: 18px;
  line-height: 150%;
  margin: 15px 0 0 0;
  color: black;
}
.score_section h5 a {
  font-size: 22px;
}
</style>
