<template>
  <div class="container" v-if="result !== ''">
    <div class="action-btns" v-if="isRoleAdmin()">
      <div>
        <a
          :href="
            '/tests/grading/' +
              type +
              '?keyboard=2&exam_id=' +
              result.exam_id +
              '&user_id=' +
              result.user_id +
              '&user_exam_id=' +
              user_exam_id
          "
          ><el-button style="width:150px;margin-top:10px;" type="success"
            ><i class="fa fa-edit"></i> 編輯答案</el-button
          ></a
        >
      </div>
      <div>
        <el-button
          style="width:150px;margin-top:10px;"
          class="testIdSave"
          type="danger"
          @click="removeUserExam"
          ><i class="fa fa-trash-alt"></i> 刪除考卷</el-button
        >
      </div>
    </div>
    <sat-result
      v-if="type === 'sat'"
      :result="result"
      :user_exam_id="user_exam_id"
    ></sat-result>
    <act-result
      v-if="type === 'act'"
      :result="result"
      :user_exam_id="user_exam_id"
    ></act-result>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SatResult from "@/views/tests/result/type/SatResult";
import ActResult from "@/views/tests/result/type/ActResult";
import TOEFL from "@/apis/tests.js";
import role from "@/mixins/role.js";

export default {
  metaInfo() {
    return {
      title: "Test Result - Ivy-Way Academy"
    };
  },

  components: {
    SatResult,
    ActResult
  },

  mixins: [role],

  props: [],
  data() {
    return { 
      bestScore: {
        english: 800,
        math: 800,
        total: 1600
      },
      result: "",
      charts: "",
      score: 1600,
      minScore: 0,
      source: []
    };
  },
  computed: {
    user_exam_id() {
      return this.$route.query.user_exam_id;
    },
    type() {
      return this.$route.params.testType;
    }
  },
  watch: {},

  mounted() {
    this.getRes();
  },
  methods: {
    async getRes() {
      const res = await TOEFL.getRes(this.type + "s", this.user_exam_id);
      this.result = res;
    },
    async refreshAnswer() {
      const res = await TOEFL.refreshAnswer(this.user_exam_id);
      this.$router.go(0);
    },
    async removeUserExam() {
      await this.$confirm("是否刪除本次考試?", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          TOEFL.removeUserExam(this.user_exam_id);
          this.routerPush({
            name: "Answers"
          });
          this.$message({
            type: "success",
            message: "已刪除"
          });
          this.$router.replace({
            name: "Tests"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消刪除"
          });
        });
    }
  }
};
</script>

<style scoped>
.sat .sat_right ul {
  list-style: none;
  position: relative;
  padding-left: 0;
}
.title {
  font-size: 30px;
}
.sat_left > div > span {
  display: inline-block;
  position: absolute;
  left: 290px;
  top: 28px;
}
.sat_left > div > p:first-child {
  font-size: 140px;
  font-weight: 700;
  color: #43a06b;
  line-height: 140px;
  margin-bottom: 0px;
}
.sat_right {
  padding-left: 0px;
  padding-top: 30px;
  border-bottom: 1px solid#DCDFE6;
}
.sat_right ul {
  clear: both;
}
.sat_right ul li {
  font-size: 20px;
  line-height: 30px;
}
.sat_right img {
  width: 90%;
}
.action-btns {
  z-index: 9999999;
  position: fixed;
  top: 120px;
  right: 0px;
  margin-bottom: 15px;
}
</style>
