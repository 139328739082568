var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.result !== '')?_c('div',{staticClass:"container"},[(_vm.isRoleAdmin())?_c('div',{staticClass:"action-btns"},[_c('div',[_c('a',{attrs:{"href":'/tests/grading/' +
            _vm.type +
            '?keyboard=2&exam_id=' +
            _vm.result.exam_id +
            '&user_id=' +
            _vm.result.user_id +
            '&user_exam_id=' +
            _vm.user_exam_id}},[_c('el-button',{staticStyle:{"width":"150px","margin-top":"10px"},attrs:{"type":"success"}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" 編輯答案")])],1)]),_c('div',[_c('el-button',{staticClass:"testIdSave",staticStyle:{"width":"150px","margin-top":"10px"},attrs:{"type":"danger"},on:{"click":_vm.removeUserExam}},[_c('i',{staticClass:"fa fa-trash-alt"}),_vm._v(" 刪除考卷")])],1)]):_vm._e(),(_vm.type === 'sat')?_c('sat-result',{attrs:{"result":_vm.result,"user_exam_id":_vm.user_exam_id}}):_vm._e(),(_vm.type === 'act')?_c('act-result',{attrs:{"result":_vm.result,"user_exam_id":_vm.user_exam_id}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }