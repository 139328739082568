<template>
  <div>
    <div class="best-score">
      <score-card :bestScore="result.highest_score"></score-card>
    </div>
    <div class="history">
      <h4 class="title" style="color:#43a06b;margin:20px 0 0 0">
        Score History
      </h4>
      <div style="overflow:auto">
        <div
          id="report"
          :style="{
            width: this.source.length * 150 + 250 + 'px',
            height: '200px',
            margin: 0
          }"
        ></div>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="row sat">
      <div class="col-md-5 sat_left print_div">
        <div>
          <p>ACT</p>
          <span><i class="fa fa-registered" aria-hidden="true"></i></span>
        </div>
      </div>
      <div class="col-md-7 sat_right row">
        <div class="col-md-8">
          <ul>
            <li>
              Name:
              <a :href="'/user/' + result.user_id"
                ><span>{{ result.first_name }} {{ result.last_name }}</span>
              </a>
              <el-tooltip
                v-if="isRoleAdmin()"
                effect="dark"
                content="Send To parents and student."
                placement="top"
              >
                <span class="btn text-success" @click="sendEmail()"
                  ><i class="far fa-envelope"></i></span
              ></el-tooltip>
            </li>
            <li>
              Test Name:
              <span>{{ result.title }}</span>
            </li>
            <li>
              Test Date: <span>{{ result.exam_date ? result.exam_date : result.date }}</span>
            </li>
          </ul>
        </div>
        <div class="col-md-4 img" style="line-height:93px;">
          <img
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo.png"
          />
        </div>
      </div>
    </div>
    <!-- Your Composite Score -->
    <div class="row total">
      <div class="col-md-6 col-sm-12 total_score print_div">
        <h4 class="title">
          Your Composite Score
          <el-button
            size="small"
            @click="progressType = 1"
            v-if="progressType == 0"
          >
            Since First Test &nbsp;
            <i class="el-icon-refresh"></i>
          </el-button>
          <el-button
            size="small"
            @click="progressType = 0"
            v-if="progressType == 1"
          >
            Since Last Test &nbsp;
            <i class="el-icon-refresh"></i>
          </el-button>
        </h4>
        <div class="total_above row">
          <p class="col-md-8 score">
            {{ result.scores.total_score }}
            <small class="differt-score">
              <span v-if="progress.total_score > 0" class="text-success">
                <i class="fas fa-caret-up"></i>
                {{ Math.abs(progress.progress_total_score) }}
              </span>
              <span
                v-if="progress.progress_total_score < 0"
                class="text-danger"
              >
                <i class="fas fa-caret-down"></i>
                {{ Math.abs(progress.progress_total_score) }}
              </span>
            </small>
          </p>
          <p class="col-sm-4 t_score">0 to 36</p>
        </div>
        <h6>Your total Score</h6>
        <div class="total_progress">
          <div class="row">
            <p class="col-md-12 text-right">
              <span class="tag tag-right"></span>Ivy-Way students
            </p>
          </div>
          <div class="progress">
            <div
              class="progress-bar progress-bar-success"
              :style="getTh(result.local_rank.total)"
            ></div>
            <span>{{ number.attachOrdinal(result.local_rank.total) }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 total_score print_div">
        <h4 class="title">
          Essay Scores
        </h4>
        <div class="total_above row">
          <p class="col-md-8 score">
            <span v-if="result.scores.essay_score > 0">
              {{ result.scores.essay_score }}
            </span>
            <span v-else>N/A</span>
          </p>
          <p class="col-sm-4 t_score">0 to 12</p>
        </div>
        <h6>Your Essay Score</h6>
      </div>
    </div>
    <!-- Your Composite Score -->
    <el-divider></el-divider>
    <div class="total">
      <h4 class="title">
        Section Scores
      </h4>
      <div class="row">
        <div class="col-md-6 col-sm-12 total_score print_div">
          <div class="total_above row">
            <p class="col-md-8 score">
              {{ result.scores.english_score }}
              <small class="differt-score">
                <span
                  v-if="progress.progress_english_score > 0"
                  class="text-success"
                >
                  <i class="fas fa-caret-up"></i>
                  {{ Math.abs(progress.progress_english_score) }}
                </span>
                <span
                  v-if="progress.progress_english_score < 0"
                  class="text-danger"
                >
                  <i class="fas fa-caret-down"></i>
                  {{ Math.abs(progress.progress_english_score) }}
                </span>
              </small>
            </p>
            <p class="col-sm-4 t_score">0 to 36</p>
          </div>
          <h6>Your English Score</h6>
          <div class="total_progress">
            <div class="row">
              <p class="col-md-12 text-right">
                <span class="tag tag-right"></span>Ivy-Way students
              </p>
            </div>
            <div class="progress">
              <div
                class="progress-bar progress-bar-success"
                :style="getTh(result.local_rank.english)"
              ></div>
              <span>{{ number.attachOrdinal(result.local_rank.english) }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 total_score print_div">
          <div class="total_above row">
            <p class="col-md-8 score">
              {{ result.scores.math_score }}
              <small class="differt-score">
                <span
                  v-if="progress.progress_math_score > 0"
                  class="text-success"
                >
                  <i class="fas fa-caret-up"></i>
                  {{ Math.abs(progress.progress_math_score) }}
                </span>
                <span
                  v-if="progress.progress_math_score < 0"
                  class="text-danger"
                >
                  <i class="fas fa-caret-down"></i>
                  {{ Math.abs(progress.progress_math_score) }}
                </span>
              </small>
            </p>
            <p class="col-sm-4 t_score">0 to 36</p>
          </div>
          <h6>Your Math Score</h6>
          <div class="total_progress">
            <div class="row">
              <p class="col-md-12 text-right">
                <span class="tag tag-right"></span>Ivy-Way students
              </p>
            </div>
            <div class="progress">
              <div
                class="progress-bar progress-bar-success"
                :style="getTh(result.local_rank.math)"
              ></div>
              <span>{{ number.attachOrdinal(result.local_rank.math) }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 total_score print_div">
          <div class="total_above row">
            <p class="col-md-8 score">
              {{ result.scores.reading_score }}
              <small class="differt-score">
                <span
                  v-if="progress.progress_reading_score > 0"
                  class="text-success"
                >
                  <i class="fas fa-caret-up"></i>
                  {{ Math.abs(progress.progress_reading_score) }}
                </span>
                <span
                  v-if="progress.progress_reading_score < 0"
                  class="text-danger"
                >
                  <i class="fas fa-caret-down"></i>
                  {{ Math.abs(progress.progress_reading_score) }}
                </span>
              </small>
            </p>
            <p class="col-sm-4 t_score">0 to 36</p>
          </div>
          <h6>Your Reading Score</h6>
          <div class="total_progress">
            <div class="row">
              <p class="col-md-12 text-right">
                <span class="tag tag-right"></span>Ivy-Way students
              </p>
            </div>
            <div class="progress">
              <div
                class="progress-bar progress-bar-success"
                :style="getTh(result.local_rank.reading)"
              ></div>
              <span>{{ number.attachOrdinal(result.local_rank.reading) }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 total_score print_div">
          <div class="total_above row">
            <p class="col-md-8 score">
              {{ result.scores.science_score }}
              <small class="differt-score">
                <span
                  v-if="progress.progress_science_score > 0"
                  class="text-success"
                >
                  <i class="fas fa-caret-up"></i>
                  {{ Math.abs(progress.progress_science_score) }}
                </span>
                <span
                  v-if="progress.progress_science_score < 0"
                  class="text-danger"
                >
                  <i class="fas fa-caret-down"></i>
                  {{ Math.abs(progress.progress_science_score) }}
                </span>
              </small>
            </p>
            <p class="col-sm-4 t_score">0 to 36</p>
          </div>
          <h6>Your Science Score</h6>
          <div class="total_progress">
            <div class="row">
              <p class="col-md-12 text-right">
                <span class="tag tag-right"></span>Ivy-Way students
              </p>
            </div>
            <div class="progress">
              <div
                class="progress-bar progress-bar-success"
                :style="getTh(result.local_rank.science)"
              ></div>
              <span>{{ number.attachOrdinal(result.local_rank.science) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Score Breakdown -->
    <el-divider></el-divider>
    <div class="total">
      <h4 class="title">
        Score Breakdown &nbsp;
        <el-button
          size="small"
          type="success"
          v-if="!showAnswer"
          @click="showAnswer = true"
        >
          Show Answers
        </el-button>
        <el-button
          size="small"
          type="danger"
          v-else
          @click="showAnswer = false"
        >
          Hide Answers
        </el-button>
      </h4>
      <div class="">
        <table id="testresult" class="table table-bordered">
          <thead>
            <tr>
              <td class="green-td"></td>
              <th class="green-td right-border" colspan="2"><b>English</b></th>
              <th class="green-td right-border" colspan="2"><b>Math</b></th>
              <th class="green-td right-border" colspan="2"><b>Reading</b></th>
              <th class="green-td right-border" colspan="2"><b>Science</b></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="green-td text-center">#</td>
              <td class="right-border text-center" colspan="2">
                <b>Section 1</b>
              </td>
              <td class="right-border text-center" colspan="2">
                <b>Section 2</b>
              </td>
              <td class="right-border text-center" colspan="2">
                <b>Section 3</b>
              </td>
              <td class="right-border text-center" colspan="2">
                <b>Section 4</b>
              </td>
            </tr>
            <tr v-for="(item, index) in arr" :key="index">
              <td class="green-td text-center">{{ index + 1 }}</td>

              <td class="hover-answer">
                <span class="text-success">
                  <span
                    v-if="
                      result.answers['english'].questions[index] !== undefined
                    "
                  >
                    <span
                      v-if="
                        result.answers['english'].questions[index]
                          .is_correct === 1
                      "
                    >
                      {{
                        result.answers["english"].questions[index]
                          .correct_answer[0]
                      }}
                    </span>
                    <span v-else>
                      <span v-show="showAnswer">
                        {{
                          result.answers["english"].questions[index]
                            .correct_answer[0]
                        }}
                      </span>
                    </span>
                  </span>
                </span>
              </td>
              <td class="right-border hover-answer">
                <span
                  v-if="
                    result.answers['english'].questions[index] !== undefined
                  "
                >
                  <a
                    :class="
                      result.answers['english'].questions[index].is_correct ===
                      1
                        ? ''
                        : 'text-danger'
                    "
                    :href="
                      result.answers['english'].questions[index]
                        .user_exam_answer_id
                        ? `/tests/act/${result.answers['english'].questions[index].act_question_id}/${result.answers['english'].questions[index].user_exam_answer_id}`
                        : ``
                    "
                    target="_blank"
                  >
                    <span
                      v-if="
                        result.answers['english'].questions[index]
                          .is_correct === 1
                      "
                    >
                      <i
                        class="fa fa-check"
                        aria-hidden="true"
                        style="color:#43a06b;"
                      ></i>
                    </span>
                    <span v-else class="text-danger">
                      <span
                        v-if="
                          result.answers['english'].questions[index].user_answer
                            .length > 0 &&
                            result.answers['english'].questions[index]
                              .user_answer['0'] !== ''
                        "
                      >
                        {{
                          result.answers["english"].questions[index]
                            .user_answer[0]
                        }}
                      </span>
                      <span v-else>
                        *
                      </span>
                    </span>
                  </a>
                </span>
              </td>

              <td class="hover-answer">
                <span class="text-success">
                  <span
                    v-if="result.answers['math'].questions[index] !== undefined"
                  >
                    <span
                      v-if="
                        result.answers['math'].questions[index].is_correct === 1
                      "
                    >
                      {{
                        result.answers["math"].questions[index]
                          .correct_answer[0]
                      }}
                    </span>
                    <span v-else>
                      <span v-show="showAnswer">
                        {{
                          result.answers["math"].questions[index]
                            .correct_answer[0]
                        }}
                      </span>
                    </span>
                  </span>
                </span>
              </td>
              <td class="right-border hover-answer">
                <span
                  v-if="result.answers['math'].questions[index] !== undefined"
                >
                  <a
                    :class="
                      result.answers['math'].questions[index].is_correct === 1
                        ? ''
                        : 'text-danger'
                    "
                    :href="
                      result.answers['math'].questions[index]
                        .user_exam_answer_id
                        ? `/tests/act/${result.answers['math'].questions[index].act_question_id}/${result.answers['math'].questions[index].user_exam_answer_id}`
                        : ``
                    "
                    target="_blank"
                  >
                    <span
                      v-if="
                        result.answers['math'].questions[index].is_correct === 1
                      "
                    >
                      <i
                        class="fa fa-check"
                        aria-hidden="true"
                        style="color:#43a06b;"
                      ></i>
                    </span>
                    <span v-else class="text-danger">
                      <span
                        v-if="
                          result.answers['math'].questions[index].user_answer
                            .length > 0 &&
                            result.answers['math'].questions[index]
                              .user_answer[0] != ''
                        "
                      >
                        {{
                          result.answers["math"].questions[index].user_answer[0]
                        }}
                      </span>
                      <span v-else>
                        *
                      </span>
                    </span>
                  </a>
                </span>
              </td>

              <td class="hover-answer">
                <span class="text-success">
                  <span
                    v-if="
                      result.answers['reading'].questions[index] !== undefined
                    "
                  >
                    <span
                      v-if="
                        result.answers['reading'].questions[index]
                          .is_correct === 1
                      "
                    >
                      {{
                        result.answers["reading"].questions[index]
                          .correct_answer[0]
                      }}
                    </span>
                    <span v-else>
                      <span v-show="showAnswer">
                        {{
                          result.answers["reading"].questions[index]
                            .correct_answer[0]
                        }}
                      </span>
                    </span>
                  </span>
                </span>
              </td>
              <td class="right-border hover-answer">
                <span
                  v-if="
                    result.answers['reading'].questions[index] !== undefined
                  "
                >
                  <a
                    :class="
                      result.answers['reading'].questions[index].is_correct ===
                      1
                        ? ''
                        : 'text-danger'
                    "
                    :href="
                      result.answers['reading'].questions[index]
                        .user_exam_answer_id
                        ? `/tests/act/${result.answers['reading'].questions[index].act_question_id}/${result.answers['reading'].questions[index].user_exam_answer_id}`
                        : ``
                    "
                    target="_blank"
                  >
                    <span
                      v-if="
                        result.answers['reading'].questions[index]
                          .is_correct === 1
                      "
                    >
                      <i
                        class="fa fa-check"
                        aria-hidden="true"
                        style="color:#43a06b;"
                      ></i>
                    </span>
                    <span v-else class="text-danger">
                      <span
                        v-if="
                          result.answers['reading'].questions[index].user_answer
                            .length > 0 &&
                            result.answers['reading'].questions[index]
                              .user_answer[0] !== ''
                        "
                      >
                        {{
                          result.answers["reading"].questions[index]
                            .user_answer[0]
                        }}
                      </span>
                      <span v-else>
                        *
                      </span>
                    </span>
                  </a>
                </span>
              </td>

              <td class="hover-answer">
                <span class="text-success">
                  <span
                    v-if="
                      result.answers['science'].questions[index] !== undefined
                    "
                  >
                    <span
                      v-if="
                        result.answers['science'].questions[index]
                          .is_correct === 1
                      "
                    >
                      {{
                        result.answers["science"].questions[index]
                          .correct_answer[0]
                      }}
                    </span>
                    <span v-else>
                      <span v-show="showAnswer">
                        {{
                          result.answers["science"].questions[index]
                            .correct_answer[0]
                        }}
                      </span>
                    </span>
                  </span>
                </span>
              </td>
              <td class="right-border hover-answer">
                <span
                  v-if="
                    result.answers['science'].questions[index] !== undefined
                  "
                >
                  <a
                    :class="
                      result.answers['science'].questions[index].is_correct ===
                      1
                        ? ''
                        : 'text-danger'
                    "
                    :href="
                      result.answers['science'].questions[index]
                        .user_exam_answer_id
                        ? `/tests/act/${result.answers['science'].questions[index].act_question_id}/${result.answers['science'].questions[index].user_exam_answer_id}`
                        : ``
                    "
                    target="_blank"
                  >
                    <span
                      v-if="
                        result.answers['science'].questions[index]
                          .is_correct === 1
                      "
                    >
                      <i
                        class="fa fa-check"
                        aria-hidden="true"
                        style="color:#43a06b;"
                      ></i>
                    </span>
                    <span v-else class="text-danger">
                      <span
                        v-if="
                          result.answers['science'].questions[index].user_answer
                            .length > 0 &&
                            result.answers['science'].questions[index]
                              .user_answer[0] !== ''
                        "
                      >
                        {{
                          result.answers["science"].questions[index]
                            .user_answer[0]
                        }}
                      </span>
                      <span v-else>
                        *
                      </span>
                    </span>
                  </a>
                </span>
              </td>
            </tr>
            <tr>
              <td class="green-td text-center">Correct</td>
              <td class="right-border text-center" colspan="2">
                {{ result.answers["english"].correct }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{ result.answers["math"].correct }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{ result.answers["reading"].correct }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{ result.answers["science"].correct }}
              </td>
            </tr>
            <tr>
              <td class="green-td text-center">Omitted</td>
              <td class="right-border text-center" colspan="2">
                {{ result.answers["english"].omitted }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{ result.answers["math"].omitted }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{ result.answers["reading"].omitted }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{ result.answers["science"].omitted }}
              </td>
            </tr>
            <tr>
              <td class="green-td text-center">Wrong</td>
              <td class="right-border text-center" colspan="2">
                {{
                  result.answers["english"].questions.length -
                    (result.answers["english"].correct +
                      result.answers["english"].omitted)
                }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{
                  result.answers["math"].questions.length -
                    (result.answers["math"].correct +
                      result.answers["math"].omitted)
                }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{
                  result.answers["reading"].questions.length -
                    (result.answers["reading"].correct +
                      result.answers["reading"].omitted)
                }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{
                  result.answers["science"].questions.length -
                    (result.answers["science"].correct +
                      result.answers["science"].omitted)
                }}
              </td>
            </tr>
            <tr>
              <td class="green-td text-center">Total</td>
              <td class="right-border text-center" colspan="2">
                {{ result.answers["english"].questions.length }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{ result.answers["math"].questions.length }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{ result.answers["reading"].questions.length }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{ result.answers["science"].questions.length }}
              </td>
            </tr>
            <tr>
              <td class="green-td text-center">Correct/Total</td>
              <td class="right-border text-center" colspan="2">
                {{
                  getPer(
                    result.answers["english"].correct,
                    result.answers["english"].questions.length
                  )
                }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{
                  getPer(
                    result.answers["math"].correct,
                    result.answers["math"].questions.length
                  )
                }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{
                  getPer(
                    result.answers["reading"].correct,
                    result.answers["reading"].questions.length
                  )
                }}
              </td>
              <td class="right-border text-center" colspan="2">
                {{
                  getPer(
                    result.answers["science"].correct,
                    result.answers["science"].questions.length
                  )
                }}
              </td>
            </tr>
            <tr>
              <td class="green-td text-center">Score</td>
              <td class="right-border text-center" colspan="2">
                <b>{{ result.scores.english_score }}</b>
              </td>
              <td class="right-border text-center" colspan="2">
                <b>{{ result.scores.math_score }}</b>
              </td>
              <td class="right-border text-center" colspan="2">
                <b>{{ result.scores.reading_score }}</b>
              </td>
              <td class="right-border text-center" colspan="2">
                <b>{{ result.scores.science_score }}</b>
              </td>
            </tr>
            <tr>
              <td class="green-td"></td>
              <td class="green-td right-border" colspan="2"><b>English</b></td>
              <td class="green-td right-border" colspan="2"><b>Math</b></td>
              <td class="green-td right-border" colspan="2"><b>Reading</b></td>
              <td class="green-td right-border" colspan="2"><b>Science</b></td>
            </tr>
          </tbody>
        </table>
        <el-button type="success" @click="showEssay = !showEssay">
          <span v-show="!showEssay">Show Essay</span>
          <span v-show="showEssay">Hide Essay</span>
        </el-button>
        <div
          v-show="showEssay"
          v-if="
            result.answers['essay'] && result.answers.essay.questions.length > 0
          "
        >
          <div class="view-image">
            <div
              class="view-image-item"
              v-for="(image, key) in result.answers.essay.questions[0]
                .user_answer.img_url"
              :key="key"
            >
              <el-image
                style="width: 150px; height: 150px"
                v-show="
                  result.answers.essay.questions[0].user_answer.img_url.length >
                    0
                "
                :src="
                  result.answers.essay.questions[0].user_answer.img_url[key]
                "
                :preview-src-list="
                  result.answers.essay.questions[0].user_answer.img_url
                "
              >
              </el-image>
            </div>
          </div>
          <div class="viewImage">
            <div
              v-html="result.answers.essay.questions[0].user_answer.essay"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Prediction Scores -->
    <el-divider></el-divider>
    <div class="total">
      <h4 class="title">
        Prediction Scores
      </h4>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="total_above row">
            <p class="col-md-8 score">
              {{ predictionScoresSAT[0] }} - {{ predictionScoresSAT[1] }}
            </p>
            <p class="col-sm-4 t_score">400 to 1600</p>
          </div>
          <h6>
            <p class="prediction-score">
              SAT prediction grade
              <span
                data-toggle="tooltip"
                title=""
                data-html="true"
                data-original-title="
              Based on this test, you are expected to get a score within this range on the real SAT.<br>"
                ><i class="far fa-question-circle fa-sm"></i
              ></span>
            </p>
          </h6>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="total_above row">
            <p class="col-md-8 score">
              {{ predictionScoresACT[0] }} - {{ predictionScoresACT[1] }}
            </p>
            <p class="col-sm-4 t_score">0 to 36</p>
          </div>
          <h6>
            <p class="prediction-score">
              ACT prediction grade
              <span
                data-toggle="tooltip"
                title=""
                data-html="true"
                data-original-title="
                Based on this test, you are expected to get a score within this range on the real SAT.<br>"
                ><i class="far fa-question-circle fa-sm"></i
              ></span>
            </p>
          </h6>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
  </div>
</template>

<script>
import _ from "lodash";
import echarts from "echarts";
import ScoreCard from "@/views/tests/result/SuperScoreAct";
import Tests from "@/apis/tests.js";
import role from "@/mixins/role.js";
import number from "@/views/tests/result/type/number.js";

export default {
  components: {
    ScoreCard
  },

  mixins: [role],

  props: ["result"],
  data() {
    return {
      // arr: [],
      progress: {},
      progressType: 1,
      showAnswer: false,
      srcList:
        this.result.answers.essay === undefined ||
        this.result.answers.essay.questions === null ||
        this.result.answers.essay.questions.length === 0
          ? []
          : [this.result.answers.essay.questions[0].user_answer.img_url],
      showEssay: false,
      bestScore: {
        english: 800,
        math: 800,
        total: 1600
      },
      charts: "",
      score: 36,
      minScore: 0,
      source: []
    };
  },
  computed: {
    number() {
      return number;
    },
    predictionScoresSAT() {
      let score =
        400 + Math.round(this.result.scores.total_score * (1200 / 36));
      let min_score = score - 50;
      let max_score = score + 50;
      if (max_score > 1600) {
        max_score = 1600;
      }
      return [min_score, max_score];
    },
    predictionScoresACT() {
      let score = this.result.scores.total_score;
      let min_score = Math.round(score) - 2;
      let max_score = Math.round(score) + 2;
      if (min_score <= 0) {
        min_score = 0;
      }
      if (max_score > 36) {
        max_score = 36;
      }
      return [min_score, max_score];
    },
    arr() {
      let english = this.result.answers["english"]
        ? this.result.answers["english"].questions.length
        : 0;
      let math = this.result.answers["math"]
        ? this.result.answers["math"].questions.length
        : 0;
      let science = this.result.answers["science"]
        ? this.result.answers["science"].questions.length
        : 0;
      let reading = this.result.answers["reading"]
        ? this.result.answers["reading"].questions.length
        : 0;
      let arr = [];
      let maxLength = _.max([english, math, science, reading]);
      for (let i = 0; i < maxLength; i++) {
        arr.push(i);
      }
      return arr;
    }
  },
  watch: {
    progressType(val) {
      if (val === 0) {
        this.progress = this.result.scores.first_progress;
      }
      if (val === 1) {
        this.progress = this.result.scores.recent_progress;
      }
    }
  },

  mounted() {
    this.$nextTick(function() {
      this.draw("report");
    });
    // let arr = [];
    // for (let i = 0; i <= 74; i++) {
    //   arr.push(i);
    // }
    // this.arr = arr;
    this.progress = this.result.scores.recent_progress;
    let source = [];
    let minScore = 38;
    this.result.history_total_score.forEach((val, index) => {
      if (val.total_score < minScore) {
        minScore = val.total_score;
      }
      source.push([
        index + 1 + ". " + val.date,
        val.total_score,
        val.date,
        val.user_exam_id,
        val.title
      ]);
    });
    this.minScore = minScore;
    this.source = source;
  },

  methods: {
    getPer(correct, total) {
      let string = "";
      if (total > 0) {
        let per = Math.round((correct / total) * 100);
        string = per + "%";
      } else {
        string = "N/A";
      }
      return string;
    },
    getCNum(val) {
      if (val == undefined) {
        val = 0;
      }
      return val;
    },
    showDiff(c_num, t_num) {
      let show = false;
      if (c_num == undefined) c_num = 0;
      if ((t_num == 1 && c_num == 0) || t_num - c_num > 1) {
        show = true;
      } else {
        show = false;
      }
      return show;
    },
    showNeed(c_num, t_num) {
      let show = false;
      if (c_num == undefined) c_num = 0;
      if (t_num > 1 && t_num - c_num == 1) {
        show = true;
      } else {
        show = false;
      }
      return show;
    },
    showGood(c_num, t_num) {
      let show = false;
      if (c_num == undefined) c_num = 0;
      if (t_num == c_num) {
        show = true;
      } else {
        show = false;
      }
      return show;
    },
    draw(id) {
      this.charts = echarts.init(document.getElementById(id));
      this.charts.setOption({
        legend: {},
        tooltip: {},
        dataset: {
          source: this.source //连接数据
        },
        xAxis: { type: "category" },
        yAxis: {
          // 这个地方如果需要在Y轴定义最大值就放开,如果需要根据数据自适应的话,就注释掉
          type: "value",
          max: this.score,
          min: 0,
          maxInterval: this.score * 0.5,
          minInterval: 1
        },
        grid: { left: 30, bottom: 30 },
        series: [
          {
            type: "bar", //表示这个是柱状图
            barCategoryGap: "40%",
            barWidth: 70,
            itemStyle: { color: "#43a06b" }, //定义颜色
            tooltip: {
              formatter: params => {
                //console.log(params)   打印这个params,按自己需要拼接字符串
                return ` <b>${params.value[4]}: ${params.value[1]} <b/>`;
              }
            }
          }
        ]
      });
    },
    async sendEmail() {
      await this.$confirm("是否寄成績通知信學生跟家長？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          Tests.sendResultEmail({
            user_exam_id: this.user_exam_id
          });
          this.$message({
            type: "success",
            message: "發送成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消發送"
          });
        });
    },
    removeResult() {},
    getTh(value) {
      let th = 0;
      th = value;
      return "width:" + th + "%";
    }
  }
};
</script>

<style scoped>
.total p,
.total ul {
  margin: 0px;
}

.total {
  margin-top: 30px;
}
.total .title {
  font-size: 26px;
  line-height: 40px;
  height: 40px;
  color: #43a06b;
}
.total_under {
  padding: 0px;
}
.total_above .score {
  font-size: 60px;
  color: #222;
  font-weight: 700;
  line-height: 70px;
  height: 70px;
}
.differt-score-true {
  color: #43a06b;
}
.differt-score {
  font-size: 25px;
  font-weight: 700;
}
.total .score + p {
  margin-top: 25px;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-left: 4px solid #ccc;
}
.total .total_above .title {
  margin-bottom: 0;
  height: 40px;
  line-height: 40px;
}
.total .total_above p.c_score {
  margin-top: 5px;
  margin-left: 20px;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  border-left: 4px solid #ccc;
}
.total_under p:first-child {
  font-size: 30px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
}
.total .range {
  font-size: 18px;
  color: #000;
}
.total_under p:last-child {
  font-size: 16px;
  color: #666;
}
.essay li > div > div:first-child {
  font-weight: 700;
  color: #222;
  padding: 0px;
}
.essay li > div > div:last-child {
  padding-left: 20px;
  border-left: 3px solid #ccc;
}
.essay li > p {
  font-size: 16px;
  color: #666;
}
.essay .e_score {
  line-height: 34px;
  border-left: 3px solid #ccc;
}
.total_progress {
  margin-top: 20px;
}
.progress {
  margin: 20px 0;
  height: 20px;
  overflow: hidden;
  line-height: 20px;
  font-size: 15px;
  background-color: #e9ecef;
  border-radius: 3px;
}
.progress-bar-info {
  background-color: #007bff;
}
.progress-bar-success {
  background-color: #43a06b;
}
.progress > span {
  position: absolute;
  left: 50%;
  color: #222;
  line-height: 20px;
}
.total_progress .tag {
  display: inline-block;
  width: 25px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10%;
}
.total_progress .tag-left {
  background: #007bff;
}
.total_progress .tag-right {
  background: #43a06b;
}
.green-td {
  width: 96px;
  text-align: center;
  color: #fff;
  background-color: #43a06b;
}

.table td {
  height: 20px;
  padding: 2px 5px;
  text-align: center;
  vertical-align: middle;
  border: #4fb81e 1px solid;
}
.table .right-border {
  border: #4fb81e 1px solid;
  border-right: 3px solid #4fb81e;
}
.total .table >>> .el-tag {
  margin: 5px 0;
}
.sat .sat_right ul {
  list-style: none;
  position: relative;
  padding-left: 0;
}
.title {
  font-size: 30px;
}
.sat_left > div > span {
  display: inline-block;
  position: absolute;
  left: 290px;
  top: 28px;
}
.sat_left > div > p:first-child {
  font-size: 140px;
  font-weight: 700;
  color: #43a06b;
  line-height: 140px;
  margin-bottom: 0px;
}
.sat_right {
  padding-left: 0px;
  padding-top: 30px;
  border-bottom: 1px solid#DCDFE6;
}
.sat_right ul {
  clear: both;
}
.sat_right ul li {
  font-size: 20px;
  line-height: 30px;
}
.sat_right img {
  width: 90%;
}
.view-image {
  margin: 10px 0;
  display: flex;
}
.view-image-item {
  margin-right: 15px;
}
.view-image-item >>> img {
  border-radius: 5px;
  border: 1px solid #ccc;
}
.sat .sat_right ul {
  list-style: none;
  position: relative;
  padding-left: 0;
}
</style>
