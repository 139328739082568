const attachOrdinal = num => {
  if (Number.isNaN(Number(num))) return "";
  if (num === 0) return String(0);
  if (4 <= num && num <= 19) {
    return `${num}th`;
  }

  const lastDigit = String(num)[String(num).length - 1];
  switch (lastDigit) {
    case "1":
      return `${num}st`;
    case "2":
      return `${num}nd`;
    case "3":
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

export default { attachOrdinal };
